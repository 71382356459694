.alert-warning {
  --bs-alert-color: var(--alert-text-color);
  --bs-alert-bg: var(--alert-bg-color);
  --bs-alert-border-color: var(--warning-color);
}

.alert-danger {
  --bs-alert-color: var(--alert-text-color);
  --bs-alert-bg: var(--alert-bg-color);
  --bs-alert-border-color: var(--error-color);
}

.alert-danger {
  --bs-alert-color: var(--alert-text-color);
  --bs-alert-bg: var(--alert-bg-color);
  --bs-alert-border-color: var(--primary-color);
}
