// Global styles for the site. Keep this as small as possible

html {
  font-size: var(--html-font-size);
}

html, body { height: 100%; overflow: hidden; }
body {
  margin: 0;
  font-family: var(--body-font-family);
  color: var(--body-text-color);
  color-scheme: var(--color-scheme);
  max-height: 100%;
  overflow-y: auto;
}

.no-transitions, .no-transitions * {
    transition: none !important;
}


hr {
    background-color: var(--hr-color);
    border-top: 0px;
}

.accent {
    background-color: var(--accent-bg-color) !important;
    color: var(--accent-text-color) !important;
    box-shadow: inset 0px 0px 8px 1px var(--accent-bg-color) !important;
    font-size: var(--accent-text-size) !important;
    font-style: italic;
    padding: 10px;
    border-radius: 6px;
}

.text-muted {
    color: var(--text-muted-color) !important;
}

.form-switch .form-check-input:checked {
    background-color: var(--primary-color);
}

.navbar-offset {
  margin-top: 75px;
}

