@import '../node_modules/swiper/swiper';

@import './theme/variables';

// Import themes which define the css variables we use to customize the app
@import './theme/themes/dark';

// Import colors for overrides of bootstrap theme
@import './theme/toastr';


// Bootstrap must be after _colors since we define the colors there
@import '../node_modules/bootstrap/scss/bootstrap';

@import '../node_modules/charts.css/dist/charts.min';



// Import all the customized theme overrides
@import './theme/components/input';
@import './theme/components/anchors';
@import './theme/components/nav';
@import './theme/components/buttons';
@import './theme/components/toast';
@import './theme/components/checkbox';
@import './theme/components/list';
@import './theme/components/navbar';
@import './theme/components/popover';
@import './theme/components/pagination';
@import './theme/components/dropdown';
@import './theme/components/accordion';
@import './theme/components/breadcrumb';
@import './theme/components/ratingstar';
@import './theme/components/modal';
@import './theme/components/card';
@import './theme/components/slider';
@import './theme/components/radios';
@import './theme/components/selects';
@import './theme/components/progress';
@import './theme/components/sidenav';
@import './theme/components/carousel';
@import './theme/components/offcanvas';
@import './theme/components/table';
@import './theme/components/alerts';
@import './theme/components/typeahead';

@import './theme/utilities/headings';
@import './theme/utilities/utilities';
@import './theme/utilities/animations';
@import './theme/utilities/global';


// Global Styles
@font-face {
  font-family: "EBGarmond";
  src: url("assets/fonts/EBGarmond/EBGaramond-VariableFont_wght.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Spartan";
  src: url("assets/fonts/Spartan/Spartan-VariableFont_wght.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("assets/fonts/Poppins/Poppins.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "League Spartan";
  src: url("assets/fonts/League_Spartan/LeagueSpartan-VariableFont_wght.ttf");
  font-display: swap;
}

label, select, .clickable {
  cursor: pointer;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
}

// Needed for fullscreen
app-root {
  scrollbar-width: thin;
  scrollbar-color: rgba(255,255,255,0.3);
}

::-webkit-scrollbar {
  width: 10px !important;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border: 3px solid transparent;
  border-radius: 8px;
  min-height: 50px;
}

body {
  font-family: 'Poppins', sans-serif;
  overflow: hidden; // When this is enabled, it will break the webtoon reader. The nav.service will automatically remove/apply on toggling them
  scrollbar-color: rgba(255,255,255,0.3) rgba(0, 0, 0, 0.1);
  scrollbar-width: thin;
  padding: 0;
  margin: 0;

  @media (max-width: $grid-breakpoints-lg) {
    /* Setting this break the readers */
    //margin-top: var(--nav-mobile-offset) !important;
    //height: calc(var(--vh)* 100 - var(--nav-mobile-offset)) !important;
  }

}

.setting-section-break {
  height: 1px;
  background-color: var(--setting-break-color);
  margin: 30px 0;
}