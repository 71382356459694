// TODO: Clean this code up

.btn-primary {
  color: var(--btn-primary-text-color);
  background-color: var(--btn-primary-bg-color);
  border-color: var(--btn-primary-border-color);

  &:hover {
    color: var(--btn-primary-hover-text-color);
    background-color: var(--btn-primary-hover-bg-color);
    border-color: var(--btn-primary-hover-border-color);
  }
}

.btn-primary-outline {
  color: var(--btn-primary-outline-text-color);
  background-color: var(--btn-primary-outline-bg-color);
  border-color: var(--btn-primary-outline-border-color);

  &:hover {
    color: var(--btn-primary-outline-hover-text-color);
    background-color: var(--btn-primary-outline-hover-bg-color);
    border-color: var(--btn-primary-outline-hover-border-color);
  }
}

.btn-secondary-outline {
  color: var(--btn-secondary-outline-text-color);
  background-color: var(--btn-secondary-outline-bg-color);
  border-color: var(--btn-secondary-outline-border-color);
  border-radius: 0;

  &:hover {
    color: var(--btn-secondary-outline-hover-text-color);
    background-color: var(--btn-secondary-outline-hover-bg-color);
    border-color: var(--btn-secondary-outline-hover-border-color);
    box-shadow: inset 0px -2px 0px 0px var(--btn-secondary-outline-text-color);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.btn-danger-outline {
  color: var(--btn-danger-outline-text-color);
  background-color: var(--btn-danger-outline-bg-color);
  border-color: var(--btn-danger-outline-border-color);

  &:hover {
    color: var(--btn-danger-outline-hover-text-color);
    background-color: var(--btn-danger-outline-hover-bg-color);
    border-color: var(--btn-danger-outline-hover-border-color);
  }
}

.btn-actions {
  color: var(--btn-fa-icon-color);
  border-radius: var(--btn-actions-border-radius);

  &:hover {
    background-color: var(--btn-actions-hover-bg);
  }

  &:active, &:focus {
    background-color: rgb(255 255 255 / 28%) !important;
    border-color: transparent !important;
  }
}

.btn-close {
  filter: var(--btn-icon-filter) !important;
}

.btn-primary:disabled, .btn-primary.disabled, .btn-tertiary.disabled {
  background-color: var(--btn-disabled-bg-color);
  color: var(--btn-disabled-text-color);
  border-color: var(--btn-disabled-border-color);
}

button:disabled, .form-control:disabled, .form-control[readonly], .disabled, :disabled {
  cursor: not-allowed;
}

.btn-link {
    color: var(--body-text-color);
    > i {
      color: var(--body-text-color) !important;
    }

    &:hover, &:focus {
      color: var(--primary-color);
    }
}

.btn-text {
  color: var(--primary-color);
  > i {
    color: var(--primary-color) !important;
  }

  &:hover, &:focus {
    color: var(--primary-color);
  }
}


.btn:focus, .btn:active, .btn:active:focus {
  box-shadow: 0 0 0 0 var(---btn-focus-boxshadow-color) !important;
}


.btn-icon, .btn-primary-text, .btn-secondary-text, .btn-danger-text {
  cursor: pointer;
  color: var(--body-text-color);
  border: none;

  &:hover, &:focus {
    color: var(--body-text-color);
    border: none;
  }
  &.active {
    > i {
      color: var(--primary-color) !important;
    }
    border: none;
  }
}

.btn-primary-text {
  color: var(--btn-primary-text-text-color);
}

.btn-secondary-text {
  color: var(--btn-secondary-text-text-color);
}

.btn-danger-text {
  color: var(--btn-danger-text-text-color);
}

.btn-outline-secondary {
  color: var(--body-text-color);
}

.btn-secondary {
  color: var(--btn-secondary-text-color);
  background-color: var(--btn-secondary-bg-color);
  border-color: var(--btn-secondary-border-color);
}

.btn.btn-secondary.alt {
    background-color: var(--btn-alt-bg-color);
    border-color: var(--btn-alt-border-color);

    &:hover {
      background-color: var(--btn-alt-hover-bg-color);
      font-weight: var(--btn-secondary-font-weight);
    }

    &:focus {
      background-color: var(--btn-alt-focus-bg-color);
      box-shadow: 0 0 0 0.05rem var(--btn-alt-focus-boxshadow-color);
      font-weight: var(--btn-secondary-font-weight);
    }
}

button i.fa {
  color: var(--btn-fa-icon-color);
}

.btn-check:focus + .btn, .btn:focus {
  box-shadow: 0 0 0 0.05rem var(--btn-focus-boxshadow-color);
}

/* Button with dropdown */
.btn-group.dropdown > button {

}
